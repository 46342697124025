function isMobileDevice() {
  // 获取用户的User-Agent字符串
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 常见的移动设备User-Agent片段
  let mobileAgents = [
    "Android",
    "iPhone",
    "iPad",
    "iPod",
    "BlackBerry",
    "Windows Phone",
    "IEMobile",
    "Opera Mini",
    "Mobile",
    "mobile",
    "Mobile Safari",
    "Opera Mobi",
    "Silk",
    "Kindle",
    "Silk-Accelerated",
  ];

  // 检查User-Agent是否包含任何移动设备的片段
  for (let i = 0; i < mobileAgents.length; i++) {
    if (userAgent.indexOf(mobileAgents[i]) > -1) {
      return true; // 是移动设备
    }
  }

  // 如果没有匹配到任何移动设备片段，则假定为非移动设备
  return false;
}
isMobileDevice()
// 使用示例
if (isMobileDevice()) {
  console.log("这是一个移动设备访问哈哈哈哈");


  // rem等比适配配置文件
  // 基准大小
  const baseSize = 75;
  // 设置 rem 函数
  function setRem() {
    // 当前页面宽度相对于 3840宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 1500;
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 2) + "px";
  }
  // 初始化
  setRem();
  // 改变窗口大小时重新设置 rem
  window.addEventListener("resize", debounce(setRem));
  //防抖
  function debounce(fn, delay) {
    const delays = delay || 500;
    let timer;
    return function () {
      const th = this;
      const args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        timer = null;
        fn.apply(th, args);
      }, delays);
    };
  }
} else {
  console.log("这不是一个移动设备访问哈哈哈");


  // rem等比适配配置文件
  // 基准大小
  const baseSize = 75;
  // 设置 rem 函数
  function setRem() {
    // 当前页面宽度相对于 3840宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 3840;
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 2) + "px";
  }
  // 初始化
  setRem();
  // 改变窗口大小时重新设置 rem
  window.addEventListener("resize", debounce(setRem));
  //防抖
  function debounce(fn, delay) {
    const delays = delay || 500;
    let timer;
    return function () {
      const th = this;
      const args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        timer = null;
        fn.apply(th, args);
      }, delays);
    };
  }
}
