import Vue from 'vue'
import 'reset-css'; //清除默认样式
import App from './App.vue'
import './plugins/element.js'
import router from '@/router'
import store from '@/store'
import * as api from '@/request/api.js'
import './utils/rem'
import Element from 'element-ui'
import VantGreen from 'vant-green';
// import 'vant-green/lib/index.css';
Vue.use(VantGreen);
Vue.use(Element)
Vue.prototype.$selectIndex = 1;
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,store,
  beforeCreate(){
    Vue.prototype.$api = api; //各个组件使用this.$api.方法名调用接口
  }
}).$mount('#app')
