const state = {
  selectIndex: 1,
};
const mutations = {
  SET_MENU_DATA(state, data) {
    state.selectIndex = data;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
